<template>
  <div class="v-genres-info">
    <explore-info-title bg="genres">
      <!-- {{ genresCategory[route.params.category].ch }} {{ genresCategory[route.params.category].en }} -->
      {{ route.params.category }}
    </explore-info-title>

    <div class="v-genres-info__content">
      <div class="normal">
        <div class="normal__content g-content">
          <normal-box-rwd-transform :data="normalPlaylistsMockData">
            <template v-slot:header>
              <explore-title title="歌單">
                <template v-slot:extra>
                  <SeeMore text="See all in search" :to="toSearchPlaylists" :show="['init']" />
                </template>
                <template v-slot:right>
                  <SeeMore text="See all in search" :to="toSearchPlaylists" :show="['tablet']" />
                </template>
              </explore-title>
            </template>
            <template v-slot="playlistsData">
              <playlists-box-normal color="white" :data="playlistsData.data" />
            </template>
          </normal-box-rwd-transform>
        </div>
      </div>

      <div class="popular popular--deco">
        <div
          :class="{
            'g-content': addGContent,
          }"
        >
          <popular-box-rwd-transform :data="popularAlbumMockData">
            <template v-slot:header>
              <explore-title title="專輯">
                <template v-slot:extra>
                  <SeeMore text="See all in search" :to="toSearchAlbums" :show="['init']" />
                </template>
                <template v-slot:right>
                  <SeeMore text="See all in search" :to="toSearchAlbums" :show="['tablet']" />
                </template>
              </explore-title>
            </template>
            <template v-slot="albumData">
              <albums-box-popular :key="i" :data="albumData.data" />
            </template>
          </popular-box-rwd-transform>
        </div>
      </div>

      <div class="g-content">
        <explore-title title="曲目">
          <template v-slot:extra>
            <SeeMore text="See all in search" :to="toSearchTracks" :show="['init']" />
          </template>
          <template v-slot:right>
            <SeeMore text="See all in search" :to="toSearchTracks" :show="['tablet']" />
          </template>
        </explore-title>
        <div class="hot-music-list-wrap">
          <hot-music-list />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { genresCategory } from '@/config/categoryConfig';
import ExploreTitle from '@/components/Explore/ExploreTitle.vue';
import AlbumsBoxPopular from '@/components/Explore/AlbumsBoxPopular.vue';
import PlaylistsBoxNormal from '@/components/Explore/PlaylistsBoxNormal.vue';
import SeeMore from '@/components/Global/SeeMore.vue';
import { useSiteStore } from '@/store/site';
import ExploreInfoTitle from '@/components/Explore/ExploreInfoTitle.vue';
import { popularAlbumMockData } from '@/config/mockData/albumsBox';
import { normalPlaylistsMockData } from '@/config/mockData/playlistsBox';
import PopularBoxRwdTransform from '@/components/Global/PopularBoxRwdTransform.vue';
import NormalBoxRwdTransform from '@/components/Global/NormalBoxRwdTransform.vue';
import useAddGContent from '@/hooks/useAddGContent';
import HotMusicList from '@/components/Explore/HotMusicList.vue';

export default defineComponent({
  name: 'genresInfo',
  components: {
    ExploreInfoTitle,
    ExploreTitle,
    AlbumsBoxPopular,
    PlaylistsBoxNormal,
    HotMusicList,
    SeeMore,
    PopularBoxRwdTransform,
    NormalBoxRwdTransform,
  },
  setup() {
    const route = useRoute();
    const addGContent = useAddGContent();

    const toSearchPlaylists = {
      name: 'search',
      query: {
        tab: 'playlists',
      },
    };

    const toSearchAlbums = {
      name: 'search',
      query: {
        tab: 'albums',
      },
    };

    const toSearchTracks = {
      name: 'search',
      query: {
        tab: 'tracks',
      },
    };

    const siteStore = useSiteStore();

    // 離開此頁時，清除背景
    onBeforeRouteLeave(() => {
      siteStore.setBackgroundImage(false);
    });

    return {
      route,
      genresCategory,
      toSearchPlaylists,
      popularAlbumMockData,
      normalPlaylistsMockData,
      addGContent,
      toSearchAlbums,
      toSearchTracks,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/albumsAndPlaylists.scss';

.v-genres-info {
  &__content {
    padding-bottom: 130px;
  }
}

.info-section {
  & + & {
    margin-top: 100px;
  }

  &__content {
    @include padding(0 20px);
  }
}
</style>
